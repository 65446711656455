<template>
    <div>
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <div class="row breadcrumbs-top d-flex">
            <h2 class="content-header-title float-start mb-0">
              Gallery Video
            </h2>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-2">
          <div class="col-12">
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb float-end">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">Gallery Video</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <section id="dashboard-ecommerce">
          <div class="row match-height">
            <div class="col-xl-12 col-md-12 col-12">
              <div class="card card-congratulation-medal">
                <div class="card-body">
                  <div class="row mb-1">
                    <div class="d-flex col-12">
                      <div class="float-start col-6">
                        <div class="input-group input-group-merge w-50">
                          <span class="input-group-text" id="basic-addon-search2"
                            ><vue-feather type="search"></vue-feather></span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search..."
                            aria-label="Search..."
                            aria-describedby="basic-addon-search2"
                            v-model="search"
                            @keyup="searchData"
                          />
                        </div>
                      </div>
                      <!-- data-bs-toggle="modal"
                      data-bs-target="#modals-slide-in" -->
                      <div class="float-end col-6">
                        <button
                          type="button"
                          class="btn btn-gradient-primary float-end"
                          @click="add"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="container">
                    <div class="row border-top d-flex">
                        <div class="col-md-5 col-12 m-3" v-for="res, key in list" :key="res.id">
                            <h3 class="text-primary font-weight-bold font-italic">{{ res.title }}</h3>
                            <div class="videoDiv" @mouseover="selectIndex(key)" @mouseout="ItemIndex = null">
                                <iframe :src="res.url" class="w-100 rounded border-0 height-250 mb-50 img-fluid"
                                :class="ItemIndex == (key) ? `hoverClass` : ``" 
                                ></iframe>

                                <div id="hoverEffect" @click="deleteImage(res.id)" >
                                    <vue-feather type="trash-2" v-show="ItemIndex == (key)" size="20"></vue-feather>
                                </div>
                                <div id="hoverEffectEdit" @click="edit(res.id)">
                                    <vue-feather type="edit" v-show="ItemIndex == (key)" size="20"></vue-feather>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <teleport to="#modal">
      <div class="modal modal-slide-in new-user-modal fade" id="modals-slide-in" :class="isShow" :style="{display: isDisplay}">
        <div class="modal-dialog">
          <form
            class="add-new-user modal-content pt-0"
            @submit.prevent="submitForm"
            @keydown="form.onKeydown($event)"
            enctype="multipart/form-data"
          >
            <input type="hidden" name="id" :v-modal="form.id" />
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="[isShow='', isDisplay='none']"
            >
              ×
            </button>
            <div class="modal-header mb-1">
              <h4 class="modal-title" id="exampleModalLabel">
                {{ modalName }} Gallery Video
              </h4>
            </div>
            <div class="modal-body flex-grow-1">
                <div class="mb-1">
                    <label class="form-label" for="basic-addon-name">Title</label>
                    <input
                        type="text" class="form-control" name="title" v-model="form.title" placeholder="Title" @keyup="
                        form.title.length > 0
                            ? [(titleError = 'none'), (this.disabled = false)]
                            : (titleError = 'block')
                        "/>
                    <div class="invalid-feedback fw-bold fst-italic" :style="{display: titleError}" > Please enter Video Title. </div>
                </div>
                <div class="mb-1">
                    <label class="form-label" for="basic-addon-name">Url</label>
                    <input
                        type="url" class="form-control" name="url" v-model="form.url" placeholder="Url" @keyup="
                        form.url.length > 0
                            ? [(urlError = 'none'), (this.disabled = false)]
                            : (urlError = 'block')
                        "/>
                    <div class="invalid-feedback fw-bold fst-italic" :style="{display: urlError}" > Please enter Url. </div>
                </div>
              <button
                type="submit"
                class="btn btn-primary me-1 data-submit"
                :disabled="disabled"
              >
                Submit
              </button>
              <button
                type="reset"
                class="btn btn-outline-secondary"
                :disabled="disabled"
                data-bs-dismiss="modal"
                @click="[isShow='', isDisplay='none']"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </teleport>
  </template>
  
  <script>
  import axios from "axios";
  import Form from 'vform';
  
  export default {
    name: "Video",
    data() {
      return {
        form: new Form({
          id: null,
          title: "",
          url: "",
        }),
        response: "",
        disabled: false,
        list: [],
        modalName: "Add",
        search: null,
        titleError: "none",
        urlError: "none",
        isShow: "",
        isDisplay: "none",
        token: localStorage.getItem('auth_token'),
        ItemIndex: null,
      };
    },
    async mounted() {
      this.getResults();
    },
    methods: {
        selectIndex(Index) {
            this.ItemIndex = Index;
        },
        async add() {
            (this.id = null),
            (this.form.title = ""),
            (this.form.url = ""),
            (this.response = "");
            this.isShow = "show"
            this.isDisplay = "block"
        },
        async getResults() {
            await axios
            .get(process.env.VUE_APP_API_URL+"/gallery_video", 
            {
                headers: {
                "Content-Type": "application/json",
                'Accept': "application/json",
                "Authorization": `Bearer ${this.token}`
                }
            })
            .then((response) => {
                this.list = response.data.data;
            });
        },
        async submitForm() {
            this.disabled = true;
            if (this.form.title.length === 0) {
                this.titleError = "block"
            } else if (this.form.url.length === 0) {
                this.urlError = "block"
            }  else { 
                if (this.form.id) {
                    await this.form.put(process.env.VUE_APP_API_URL+"/gallery_video/"+ this.form.id,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": `Bearer ${this.token}`
                        },
                    })
                    .then((response) => {
                        if (response.data.status) {
                            this.$toast.success(response.data.message);
                            this.isShow = ""
                            this.isDisplay = "none"
                            this.disabled = false;
                            this.getResults();
                        } else {
                            this.$toast.error(response.data.error?.[0]);
                            this.disabled = false;
                        }
                    })
                    .catch((error) => {
                        this.$toast.error(error);
                        this.disabled = false;
                    });
                } else {
                    await this.form.post(process.env.VUE_APP_API_URL+"/gallery_video",
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": `Bearer ${this.token}`
                        },
                    })
                    .then((response) => {
                        if (response.data.status) {
                            this.$toast.success(response.data.message);
                            this.isShow = ""
                            this.isDisplay = "none"
                            this.disabled = false;
                            this.getResults();
                        } else {
                            this.$toast.error(response.data.error?.[0]);
                            this.disabled = false;
                        }
                    })
                    .catch((error) => {
                        this.$toast.error(error);
                        this.disabled = false;
                    });
                }
            }
        },
        async edit(id) {
        this.titleError = "none";
        this.urlError = "none";
        this.disabled = false

        const output = await axios.get(process.env.VUE_APP_API_URL+"/gallery_video" +(id ? "/" + id : ""),
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.token}`
                  },
                });
        this.form.id = output.data.data?.id;
        this.form.title = output.data.data?.title;
        this.form.url = output.data.data?.url;
        
        this.modalName = "Edit";
        this.isShow = "show"
        this.isDisplay = "block"
      },
        deleteImage(id) {
            this.$swal({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ms-1",
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    axios.delete(process.env.VUE_APP_API_URL+"/gallery_video/" + id,
                        {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": `Bearer ${this.token}`
                        },
                        }
                    )
                    .then(response => {
                        if (response.data.status) {
                            this.getResults();
                            this.$toast.success(response.data.message);
                        } else {
                            this.$toast.error(response.data.error?.[0]);
                        }
                    })
                    .catch((error) => {
                        this.$toast.error(error);
                    });
                }
            });
        },
        async searchData() {
            await axios
            .post(process.env.VUE_APP_API_URL+"/gallery_video/search",
                {
                    search: this.search,
                },
                {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.token}`
                },
                }
            )
            .then((response) => {
                if (response.data.status) {
                this.list = response.data.data;
                this.isShow = ""
                this.isDisplay = "none"
                } else {
                this.$toast.error(response.data.error?.[0]);
                }
            })
            .catch((error) => {
                this.$toast.error(error);
            });
        },
    },
    
  };
  </script>
  <style scoped>
  .villa_image {
    position: relative;
    opacity: 1;
  }
  .villa_image .img-fluid {
    z-index: -1;
  }
  .item-img {
    padding-bottom: 0.5rem;
  }
  .wish_list a .img-fluid {
    min-height: 25rem;
    min-width: 25rem;
    max-width: 25rem;
    max-height: 25rem;
    display: flex;
    text-align: center;
  }

.videoDiv {
    position: relative;
    overflow: hidden;
}
#hoverEffect i {
    position: absolute;
    right: 0;
    bottom: 15px;
    z-index: 2;
    color: #ea5455 !important;
    background: rgba(234, 84, 85, 0.5) !important;
    border: #ea5455 3px solid;
    border-radius: 50%;
    font-size: 10px !important;
    padding: 12px;
    cursor:  pointer;
    opacity: 1;
    transition: all 0.2s ease;
}
#hoverEffectEdit i {
    position: absolute;
    right: 0;
    bottom: 75px;
    z-index: 2;
    color: #28c76f !important;
    background: rgba(40, 199, 111, 0.5) !important;
    border-radius: 50%;
    font-size: 10px !important;
    padding: 12px;
    cursor:  pointer;
    opacity: 0.9;
    border: 3px solid #28c76f !important;
}
#hoverEffectEdit i :hover, #hoverEffect i :hover {
    font-size: 15px !important;
    opacity: 1;
    transition: all 0.2s ease;
    padding: 5px;
}

</style>